export const container_classname = "lg:max-w-7xl lg:mx-auto px-5 w-full";
export const sub_section_heading = "lg:text-6xl text-4xl font-semibold text-gray-900";

export interface ColumnItemsType {
    header: string;
    items: {
        name: string;
        link?: string;
        externalLink?: string;
        isNew?: boolean;
    }[];
}

export interface FooterDataProps {
    title: string;
    columnItems: ColumnItemsType[];
}

export const footerData1: FooterDataProps[] = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Product",
                items: [
                    {
                        name: "WhatsApp Flows Builder",
                        link: "/whatsapp-flows",
                    },
                    {
                        name: "WhatsApp Business API Provider",
                        link: "/whatsapp-business-api",
                    },
                    {
                        name: "WhatsApp Chatbot Platform",
                        link: "/whatsapp-chatbot",
                    },
                    {
                        name: "Whatsapp Broadcast Software",
                        link: "/whatsapp-broadcast",
                    },

                    {
                        name: "WhatsApp Payment Automation Solutions",
                        link: "/whatsapp-payments",
                    },
                    {
                        name: "WhatsApp Shared Inbox Software",
                        link: "/whatsapp-shared-inbox",
                    },
                    {
                        name: "WhatsApp Drip Marketing Software",
                        link: "/whatsapp-drip-marketing",
                    },

                    {
                        name: "Click to WhatsApp Ads Software",
                        link: "/click-to-whatsapp-ads",
                    },
                    {
                        name: "WhatsApp Store Builder",
                        link: "/build-your-shop-on-whatsapp",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Integrations",
                items: [
                    {
                        name: "Calendly",
                        link: "/whatsapp-integrations/calendly",
                    },
                    {
                        name: "Zoho CRM",
                        link: "/whatsapp-integrations/zoho-books",
                    },
                    {
                        name: "LeadSquared",
                        link: "/whatsapp-integrations/lead-squared",
                        isNew: true,
                    },
                    {
                        name: "Odoo",
                        link: "/whatsapp-integrations/odoo",
                    },
                    {
                        name: "MoEngage",
                        link: "/whatsapp-integrations/moengage",
                    },
                    {
                        name: "Shopify",
                        link: "/whatsapp-integrations/shopify",
                    },
                    {
                        name: "Zapier ",
                        link: "/whatsapp-integrations/zapier",
                    },
                    {
                        name: "RazorPay",
                        link: "/whatsapp-integrations/razorpay",
                    },
                    {
                        name: "All Integrations",
                        link: "/whatsapp-integrations",
                    },
                ],
            },
        ],
    },
    {
        title: "column3",
        columnItems: [
            {
                header: "Resources",
                items: [
                    {
                        name: "Blog",
                        link: "/blog",
                    },
                    {
                        name: "Newsroom",
                        link: "/news",
                    },
                    {
                        name: "Ebooks",
                        link: "/resources/downloadables",
                    },
                    {
                        name: "Case Studies",
                        link: "/case-study",
                    },
                    {
                        name: "Templates",
                        link: "/resources/free-whatsapp-message-templates",
                    },
                    {
                        name: "Help Center",
                        externalLink: "https://docs.gallabox.com",
                    },
                    {
                        name: "Developer API",
                        externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
                    },
                ],
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Compare",
                items: [
                    {
                        name: "with Wati",
                        link: "/best-wati-alternative",
                    },
                    {
                        name: "with Aisensy",
                        link: "/best-aisensy-alternative",
                    },
                    {
                        name: "with Rasayel",
                        link: "/best-rasayel-alternative",
                    },
                    {
                        name: "with Zoko",
                        link: "/zoko-alternative",
                    },
                    {
                        name: "with Verloop",
                        link: "/verloop-alternative",
                    },
                    {
                        name: "with Interakt",
                        link: "/interakt-alternative",
                    },
                    {
                        name: "with Infobip",
                        link: "/infobip-alternative",
                    },
                    {
                        name: "with GetChat",
                        link: "/best-getchat-alternative",
                    },
                ],
            },
        ],
    },
    {
        title: "column5",
        columnItems: [
            {
                header: "Company",
                items: [
                    {
                        name: "About",
                        link: "/about",
                    },
                    {
                        name: "Customers",
                        link: "/case-study",
                    },
                    {
                        name: "Careers",
                        externalLink: "https://wellfound.com/company/gallabox",
                    },
                    {
                        name: "Contact",
                        link: "/contact",
                    },
                    {
                        name: "Support Center",
                        externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
                    },
                    {
                        name: "Partner with us",
                        link: "/gallabox-partner-program",
                    },
                    {
                        name: "FAQ",
                        externalLink: "https://docs.gallabox.com/?q=",
                    },
                ],
            },
        ],
    },
    {
        title: "column6",
        columnItems: [
            {
                header: "Best tools by category",
                items: [
                    {
                        name: "Best WhatsApp Tools",
                        link: "/blog/whatsapp-tools",
                    },
                    {
                        name: "Best WhatsApp Business Tools",
                        link: "/blog/whatsapp-business-tools",
                    },
                    {
                        name: "Best WhatsApp Automation Tools",
                        link: "/blog/whatsapp-automation-tools",
                    },
                    {
                        name: "Best WhatsApp Chatbot Tools",
                        link: "/blog/whatsapp-chatbot-tools",
                    },
                    {
                        name: "Best Whatsapp Marketing Softwares",
                        link: "/blog/whatsapp-marketing-software",
                    },
                    {
                        name: "Best Whatsapp Business Solution Providers",
                        link: "/blog/whatsapp-business-solution-providers",
                    },
                ],
            },
        ],
    },
    {
        title: "column7",
        columnItems: [
            {
                header: "WhatsApp guides",
                items: [
                    {
                        name: "WhatsApp Business Account",
                        link: "/blog/whatsapp-business-account",
                    },
                    {
                        name: "WhatsApp Broadcast",
                        link: "/blog/whatsapp-broadcast",
                    },
                    {
                        name: "WhatsApp Chatbot",
                        link: "/blog/whatsapp-chatbot",
                    },
                    {
                        name: "WhatsApp Flows",
                        link: "/blog/whatsapp-flow",
                    },
                    {
                        name: "WhatsApp Catalog",
                        link: "/blog/whatsapp-catalog",
                    },
                    {
                        name: "WhatsApp Business API",
                        link: "/blog/whatsapp-business-api",
                    },
                ],
            },
        ],
    },
    {
        title: "column8",
        columnItems: [
            {
                header: "Top blogs",
                items: [
                    {
                        name: "WhatsApp Marketing",
                        link: "/blog/whatsapp-marketing",
                    },
                    {
                        name: "WhatsApp Schedule Message",
                        link: "/blog/whatsapp-schedule-message",
                    },
                    {
                        name: "WhatsApp Bulk Message",
                        link: "/blog/whatsapp-bulk-message",
                    },
                    {
                        name: "WhatsApp Lead Generation",
                        link: "/blog/whatsapp-lead-generation",
                    },
                    {
                        name: "WhatsApp Auto Reply",
                        link: "/blog/whatsapp-auto-reply",
                    },
                    {
                        name: "WhatsApp Automation",
                        link: "/blog/whatsapp-automation",
                    },
                ],
            },
        ],
    },
    {
        title: "column9",
        columnItems: [
            {
                header: "Free tools",
                items: [
                    {
                        name: "WhatsApp Chat Generator",
                        link: "/ai-whatsapp-template-generator",
                    },
                    {
                        name: "WhatsApp Chat Button",
                        link: "/whatsapp-chat-button",
                    },
                    {
                        name: "WhatsApp Link Generator",
                        link: "/whatsapp-link-generator",
                    },
                    {
                        name: "WhatsApp QR Code Generator",
                        link: "/whatsapp-qr-code-generator",
                    },
                    {
                        name: "WhatsApp Pricing Calculator",
                        link: "/whatsapp-conversation-pricing-calculator",
                    },
                ],
            },
        ],
    },
    {
        title: "column10",
        columnItems: [
            {
                header: "Alternatives",
                items: [
                    {
                        name: "Wati Alternatives",
                        link: "/alternatives/wati",
                    },
                    {
                        name: "Interakt Alternatives",
                        link: "/alternatives/interakt",
                    },
                    {
                        name: "Aisensy Alternatives",
                        link: "/alternatives/aisensy",
                    },
                    // {
                    //     name: "Rasayel Alternatives",
                    //     link: "",
                    // },
                    // {
                    //     name: "QuickReply Alternatives",
                    //     link: "",
                    // },
                    // {
                    //     name: "Zixflow Alternatives",
                    //     link: "",
                    // },
                    // {
                    //     name: "DoubleTick Alternatives",
                    //     link: "",
                    // },
                ],
            },
        ],
    },
];

export const mobileFooterData: FooterDataProps[] = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Product",
                items: [
                    {
                        name: "WhatsApp Flows Builder",
                        link: "/whatsapp-flows",
                    },
                    {
                        name: "WhatsApp Business API Provider",
                        link: "/whatsapp-business-api",
                    },
                    {
                        name: "WhatsApp Chatbot Platform",
                        link: "/whatsapp-chatbot",
                    },
                    {
                        name: "Whatsapp Broadcast Software",
                        link: "/whatsapp-broadcast",
                    },

                    {
                        name: "WhatsApp Payment Automation Solutions",
                        link: "/whatsapp-payments",
                    },
                    {
                        name: "WhatsApp Shared Inbox Software",
                        link: "/whatsapp-shared-inbox",
                    },
                    {
                        name: "WhatsApp Drip Marketing Software",
                        link: "/whatsapp-drip-marketing",
                    },

                    {
                        name: "Click to WhatsApp Ads Software",
                        link: "/click-to-whatsapp-adsx",
                    },
                    {
                        name: "WhatsApp Store Builder",
                        link: "/build-your-shop-on-whatsapp",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Integrations",
                items: [
                    {
                        name: "Calendly",
                        link: "/whatsapp-integrations/calendly",
                    },
                    {
                        name: "Zoho CRM",
                        link: "/whatsapp-integrations/zoho-books",
                    },
                    {
                        name: "LeadSquared",
                        link: "/whatsapp-integrations/lead-squared",
                        isNew: true,
                    },
                    {
                        name: "Odoo",
                        link: "/whatsapp-integrations/odoo",
                    },
                    {
                        name: "MoEngage",
                        link: "/whatsapp-integrations/moengage",
                    },
                    {
                        name: "Shopify",
                        link: "/whatsapp-integrations/shopify",
                    },
                    {
                        name: "Zapier ",
                        link: "/whatsapp-integrations/zapier",
                    },
                    {
                        name: "RazorPay",
                        link: "/whatsapp-integrations/razorpay",
                    },
                    {
                        name: "All Integrations",
                        link: "/whatsapp-integrations",
                    },
                ],
            },
        ],
    },
    {
        title: "column3",
        columnItems: [
            {
                header: "Resources",
                items: [
                    {
                        name: "Blog",
                        link: "/blog",
                    },
                    {
                        name: "Newsroom",
                        link: "/news",
                    },
                    {
                        name: "Ebooks",
                        link: "/resources/downloadables",
                    },
                    {
                        name: "Case Studies",
                        link: "/case-study",
                    },
                    {
                        name: "Templates",
                        link: "/resources/free-whatsapp-message-templates",
                    },
                    {
                        name: "Help Center",
                        externalLink: "https://docs.gallabox.com",
                    },
                    {
                        name: "Developer API",
                        externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
                    },
                ],
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Compare",
                items: [
                    {
                        name: "with Wati",
                        link: "/best-wati-alternative",
                    },
                    {
                        name: "with Aisensy",
                        link: "/best-aisensy-alternative",
                    },
                    {
                        name: "with Rasayel",
                        link: "/best-rasayel-alternative",
                    },
                    {
                        name: "with Zoko",
                        link: "/zoko-alternative",
                    },
                    {
                        name: "with Verloop",
                        link: "/verloop-alternative",
                    },
                    {
                        name: "with Interakt",
                        link: "/interakt-alternative",
                    },
                    {
                        name: "with Infobip",
                        link: "/infobip-alternative",
                    },
                    {
                        name: "with GetChat",
                        link: "/best-getchat-alternative",
                    },
                ],
            },
        ],
    },
    {
        title: "column5",
        columnItems: [
            {
                header: "Company",
                items: [
                    {
                        name: "About",
                        link: "/about",
                    },
                    {
                        name: "Customers",
                        link: "/case-study",
                    },
                    {
                        name: "Careers",
                        externalLink: "https://wellfound.com/company/gallabox",
                    },
                    {
                        name: "Contact",
                        link: "/contact",
                    },
                    {
                        name: "Support Center",
                        externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
                    },
                    {
                        name: "Partner with us",
                        link: "/gallabox-partner-program",
                    },
                    {
                        name: "FAQ",
                        externalLink: "https://docs.gallabox.com/?q=",
                    },
                ],
            },
        ],
    },
    {
        title: "column6",
        columnItems: [
            {
                header: "Best tools by category",
                items: [
                    {
                        name: "Best WhatsApp Tools",
                        link: "/blog/whatsapp-tools",
                    },
                    {
                        name: "Best WhatsApp Business Tools",
                        link: "/blog/whatsapp-business-tools",
                    },
                    {
                        name: "Best WhatsApp Automation Tools",
                        link: "/blog/whatsapp-automation-tools",
                    },
                    {
                        name: "Best WhatsApp Chatbot Tools",
                        link: "/blog/whatsapp-chatbot-tools",
                    },
                    {
                        name: "Best Whatsapp Marketing Softwares",
                        link: "/blog/whatsapp-marketing-software",
                    },
                    {
                        name: "Best Whatsapp Business Solution Providers",
                        link: "/blog/whatsapp-business-solution-providers",
                    },
                ],
            },
        ],
    },
    {
        title: "column7",
        columnItems: [
            {
                header: "WhatsApp guides",
                items: [
                    {
                        name: "WhatsApp Business Account",
                        link: "/blog/whatsapp-business-account",
                    },
                    {
                        name: "WhatsApp Broadcast",
                        link: "/blog/whatsapp-broadcast",
                    },
                    {
                        name: "WhatsApp Chatbot",
                        link: "/blog/whatsapp-chatbot",
                    },
                    {
                        name: "WhatsApp Flows",
                        link: "/blog/whatsapp-flow",
                    },
                    {
                        name: "WhatsApp Catalog",
                        link: "/blog/whatsapp-catalog",
                    },
                    {
                        name: "WhatsApp Business API",
                        link: "/blog/whatsapp-business-api",
                    },
                ],
            },
        ],
    },
    {
        title: "column8",
        columnItems: [
            {
                header: "Top blogs",
                items: [
                    {
                        name: "WhatsApp Marketing",
                        link: "/blog/whatsapp-marketing",
                    },
                    {
                        name: "WhatsApp Schedule Message",
                        link: "/blog/whatsapp-schedule-message",
                    },
                    {
                        name: "WhatsApp Bulk Message",
                        link: "/blog/whatsapp-bulk-message",
                    },
                    {
                        name: "WhatsApp Lead Generation",
                        link: "/blog/whatsapp-lead-generation",
                    },
                    {
                        name: "WhatsApp Auto Reply",
                        link: "/blog/whatsapp-auto-reply",
                    },
                    {
                        name: "WhatsApp Automation",
                        link: "/blog/whatsapp-automation",
                    },
                ],
            },
        ],
    },
    {
        title: "column9",
        columnItems: [
            {
                header: "Free tools",
                items: [
                    {
                        name: "WhatsApp Chat Generator",
                        link: "/ai-whatsapp-template-generator",
                    },
                    {
                        name: "WhatsApp Chat Button",
                        link: "/whatsapp-chat-button",
                    },
                    {
                        name: "WhatsApp Link Generator",
                        link: "/whatsapp-link-generator",
                    },
                    {
                        name: "WhatsApp QR Code Generator",
                        link: "/whatsapp-qr-code-generator",
                    },
                    {
                        name: "WhatsApp Pricing Calculator",
                        link: "/whatsapp-conversation-pricing-calculator",
                    },
                ],
            },
        ],
    },
    {
        title: "column10",
        columnItems: [
            {
                header: "Alternatives",
                items: [
                    {
                        name: "Wati Alternatives",
                        link: "/alternatives/wati",
                    },
                    {
                        name: "Interakt Alternatives",
                        link: "/alternatives/interakt",
                    },
                    {
                        name: "Aisensy Alternatives",
                        link: "/alternatives/aisensy",
                    },
                    // {
                    //     name: "Rasayel Alternatives",
                    //     link: "",
                    // },
                    // {
                    //     name: "QuickReply Alternatives",
                    //     link: "",
                    // },
                    // {
                    //     name: "Zixflow Alternatives",
                    //     link: "",
                    // },
                    // {
                    //     name: "DoubleTick Alternatives",
                    //     link: "",
                    // },
                ],
            },
        ],
    },
];

export const mohitCalender = "https://calendly.com/mohit-sharma-gallabox/partnerships";
